@import "./variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Nunito";
}

body {
  background-color: $offBlack;
  width: 100%;
  overflow-x: hidden;
}
