@import "../variables.scss";

.card {
  border-radius: 0.3rem;
  background-color: $cardBackground;
  width: 30rem;
  height: 45rem;
  &:hover {
    box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.2);
  }
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  border: 1px solid $offpurple;
  align-items: center;
  text-align: center;
  padding: 5rem 2rem;
  z-index: 10;
  transform-origin: left;
  transform: perspective(1500px) rotateY(90deg);
  @media only screen and (max-width: 26.875em) {
    padding: 8rem 2rem;
    word-wrap: break-word;
  }
  &__title {
    color: $cardTitle;
    font-size: 2rem;
    font-weight: 600;
    z-index: 10;
    height: 10rem;
    text-transform: uppercase;
    pointer-events: none;
  }

  &__text {
    color: $cardText;
    pointer-events: none;
    font-size: 1.6rem;
    font-weight: 300;
    z-index: 10;
    text-align: center;
  }
}
