@import "../variables.scss";

.how-we-work {
  background: $howWeWorkBackground;
  padding: 10rem 15rem;
  scroll-margin-top: 6rem;
  @media only screen and (max-width: 91.25em) {
    padding: 8.1rem 5.5rem 12.2rem;
  }

  @media only screen and (max-width: 75.0625em) {
    padding: 54px 30px 122px;
  }

  @media only screen and (max-width: 50.9375em) {
    padding: 54px 15px 5.4rem;
  }

  @media only screen and (max-width: 38.65em) {
    scroll-margin-top: 9rem;
  }

  &__heading {
    font-size: 8.1rem;
    font-weight: 300;
    line-height: 10rem;
    letter-spacing: 0.8rem;
    color: $howWeWorkHeading;
    opacity: 0;
    position: relative;
    left: -10rem;
    transition: all 1s ease;
    width: fit-content;
    @media only screen and (max-width: 109.375em) {
      font-size: 5.4rem;
      font-weight: 300;
      line-height: 6.7rem;
    }

    @media only screen and (max-width: 59.375em) {
      font-size: 3.6rem;
      font-weight: 300;
      line-height: 4.5rem;
    }

    .borderBottom {
      width: 0%;
      height: 2px;
      background-color: $offpurple;
      transition: width 1s ease 0.2s;
      transform: translateX(2px);
    }
  }

  &__content {
    margin: 5.4rem 0;
    display: flex;
    @media only screen and (max-width: 109.375em) {
      margin: 6.5rem 0;
    }

    @media screen and (max-width: 50.9375em) {
      flex-direction: column;
      margin: 3.6rem 0;
    }
  }
}
