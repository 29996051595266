@import "../variables.scss";

.ourValues {
  width: 100%;
  padding: 15rem;
  scroll-margin-top: 2rem;
  @media only screen and (max-width: 64em) {
    padding: 10rem 5rem;
  }
  @media only screen and (max-width: 36em) {
    scroll-margin-top: 8rem;
  }
  @media only screen and (max-width: 34em) {
    padding: 10rem 3rem;
  }
  @media only screen and (max-width: 26em) {
    padding: 5rem 1rem;
  }
  @media only screen and (max-width: 38.65em) {
    scroll-margin-top: 6rem;
  }
  @media only screen and (max-width: 26em) {
    scroll-margin-top: 9rem;
  }
  background-color: $ourValuesBackground;
  &__content {
    display: flex;
    width: 100%;
    gap: 5rem;
    align-items: flex-start;
    @media only screen and (max-width: 48em) {
      flex-direction: column;
    }
    &-title {
      font-size: 4rem;
      color: $ourValuesTitle;
      width: 40%;
      position: sticky;
      top: 15rem;
      opacity: 0;
      margin-bottom: 8rem;
      transition: all 1s ease;
      letter-spacing: 0.8rem;
      & > div {
        color: rgba($ourValuesTitle, 0.8);
        font-size: 1.4rem;
        margin-top: 1rem;
        letter-spacing: normal;
        @media only screen and (max-width: 48em) {
          font-size: 1.6rem;
        }
      }
      @media only screen and (max-width: 48em) {
        position: relative;
        top: 0;
        width: 100%;
        margin-bottom: 4rem;
      }
    }
    &-value {
      padding: 10rem 0rem;
      border-top: 0.2rem solid $offWhite;
      display: flex;
      justify-content: space-between;
      position: relative;
      left: 75vw;
      opacity: 0;
      transition: all 1s ease;
      &:hover {
        border-top: 0.2rem solid $offpurple;
      }
      &-Title {
        color: $ourValuesValueTitle;
        font-size: 3rem;
        width: 40%;
        overflow-wrap: break-word;
        @media only screen and (max-width: 48em) {
          font-size: 2.6rem;
        }
        @media only screen and (max-width: 34em) {
          font-size: 2rem;
        }
      }
      &-Description {
        color: $ourValuesValueDescription;
        font-size: 1.6rem;
        width: 55%;
        line-height: 2.4rem;
        @media only screen and (max-width: 34em) {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
      @media only screen and (max-width: 64em) {
        padding: 5rem 0rem;
      }
    }
    &-valuesContainer {
      width: 60%;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      @media only screen and (max-width: 48em) {
        width: 100%;
      }
    }
  }
}
