@import "../variables.scss";

.borderBottom {
  width: 0%;
  height: 2px;
  background-color: $offpurple;
  transition: width 1s ease;
  transform: translateX(-0.4rem);
}

.about {
  width: 100%;
  min-height: 100vh;
  background-color: $aboutbackground;
  scroll-margin-top: 6rem;

  .wrapper {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    padding: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 87.5em) {
      padding: 10rem;
    }
    @media only screen and (max-width: 64em) {
      padding: 5rem;
    }
  }

  @media only screen and (max-width: 38.65em) {
    scroll-margin-top: 9rem;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10rem;
    perspective: 1500px;
    @media only screen and (max-width: 78em) {
      gap: 5rem;
    }
    @media only screen and (max-width: 48em) {
      flex-direction: column;
      align-items: center;
    }
    &-title {
      font-size: 3rem;
      letter-spacing: 0.8rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      opacity: 0;
      position: relative;
      top: 5rem;
      transition: all 1s ease;
      color: $aboutheading;
    }
    &-text {
      font-size: 1.6rem;
      position: relative;
      opacity: 0;
      top: 5rem;
      transition: all 1s ease;
      color: $abouttext;
      font-weight: 300;
      @media only screen and (max-width: 48em) {
        text-align: center;
      }
    }
  }
  &__left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5rem;
    flex: 1;
    max-width: 50rem;
    @media only screen and (max-width: 48em) {
      align-items: center;
    }
  }
  &__right {
    position: relative;
    opacity: 0;
    left: 50rem;
    transition: all 1s ease;
    align-self: flex-end;
    @media only screen and (max-width: 64em) {
      align-self: center;
    }
    &:hover {
      transform: translateZ(-100px) rotateY(20deg);
    }
  }
  .image__right {
    position: absolute;
    height: 25rem;
    border: 1.2rem solid $white;
    top: 5rem;
    right: -8rem;
    display: block;
    @media only screen and (max-width: 78em) {
      height: 15rem;
      right: -5rem;
    }
    @media only screen and (max-width: 64em) {
      height: 15rem;
      top: 2.5rem;
      border: 0.8rem solid $white;
    }
    @media only screen and (max-width: 30em) {
      display: none;
    }
  }
  .image__left {
    height: 35rem;
    @media only screen and (max-width: 78em) {
      height: 25rem;
    }
    @media only screen and (max-width: 64em) {
      height: 20rem;
    }
    @media only screen and (max-width: 30em) {
      height: 15rem;
    }
  }
}
