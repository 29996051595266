@import "../variables.scss";

.landingText {
  display: flex;
  background-color: $landingText;
  position: relative;
  &__slogan {
    z-index: 2;
    color: $slogancolor;
    font-size: 6rem;
    line-height: 6rem;
    @media only screen and (max-width: 48em) {
      font-size: 4rem;
      line-height: 4rem;
    }
    text-align: left;
    text-transform: capitalize;
  }

  #second_line {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }

  &__changableText {
    display: inline-block;
    height: 7rem;
    @media only screen and(max-width:48em) {
      height: 5rem;
    }
    overflow: hidden;
    transition: all 1s;
    &-helper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      font-size: 6rem;
      line-height: 6rem;
      animation: TextAnimation 6s ease forwards 1;
      animation-delay: 1s;
      gap: 2rem;
      transform: translateY(-24rem);
      @media only screen and (max-width: 48em) {
        font-size: 4rem;
        line-height: 4rem;
        transform: translateY(-15rem);
        gap: 1rem;
        animation-name: TextAnimationSmall;
      }
      & > div:nth-child(1) {
        color: #8facff;
      }
      & > div:nth-child(2) {
        color: #ffcc3e;
      }
      & > div:nth-child(3) {
        color: #ff3e6c;
      }
      & > div:nth-child(4) {
        color: #5f5fff;
      }
    }
  }
}

.landing {
  background-image: linear-gradient(90deg, $gradientBlue1, $gradientBlue2),
    url("../assets/bg1.jpg");

  background-size: cover;
  height: 100vh;
  width: 100%;
  padding: 15rem;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: 62.5em) {
    padding: 10rem 5rem;
  }
  @media only screen and (max-width: 48em) {
    padding: 15rem 5rem;
  }
  @media only screen and (max-width: 36em) {
    justify-content: flex-start;
  }
}

@keyframes TextAnimation {
  0% {
    transform: translateY(-24rem);
  }
  10% {
    transform: translateY(-16rem);
  }
  25% {
    transform: translateY(-16rem);
  }
  35% {
    transform: translateY(-8rem);
  }
  50% {
    transform: translateY(-8rem);
  }
  60% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes TextAnimationSmall {
  0% {
    transform: translateY(-15rem);
  }
  10% {
    transform: translateY(-10rem);
  }
  25% {
    transform: translateY(-10rem);
  }
  35% {
    transform: translateY(-5rem);
  }
  50% {
    transform: translateY(-5rem);
  }
  60% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
