@import "../variables.scss";

.navbar {
  width: 100%;
  height: 7rem;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  @media only screen and (max-width: 48em) {
    padding: 1.5rem 1rem;
  }
  @media only screen and (max-width: 36em) {
    padding: 1.5rem 2rem;
  }
  &__logo {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  &__image {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: $navimage;
    transition: all 0.5s;
  }
  &__heading {
    font-family: "raleway";
    font-size: 2.4rem;
    color: $navheading;
    transition: all 0.3s ease;
  }
  &__links {
    display: flex;
    transition: all 0.2s ease;
    @media only screen and (max-width: 56em) {
      display: none;
    }
    padding: 2rem;
    gap: 3.75rem;
    font-family: "raleway";
    font-size: 1.6rem;
    font-weight: bold;
    @media only screen and (max-width: 67.5em) {
      gap: 1.1rem;
    }
    @media only screen and (max-width: 48em) {
      gap: 2rem;
    }
    & *:link,
    & * :visited {
      text-decoration: none;
      color: $navlink;
    }
  }
  z-index: 1000;
}

.checkbox {
  display: none;
}
.checkbox:checked + .chkbox {
  background-color: transparent;
  &::after {
    transform: translateY(0rem) rotateZ(-405deg);
  }
  &::before {
    transform: translateY(0rem) rotateZ(405deg);
  }
}

.checkbox:checked ~ .hamburg__links {
  right: 0rem;
}

.chkbox {
  display: block;
  position: relative;
  height: 0.2rem;
  width: 20px;
  background-color: $offWhite;
  transition: all 0.3s ease;

  &::after,
  &::before {
    content: "";
    height: 0.2rem;
    width: 20px;
    position: absolute;
    background-color: $offWhite;
    transition: all 0.3s ease;
  }
  &::after {
    transform: translateY(0.8rem);
  }
  &::before {
    transform: translateY(-0.8rem);
  }
}

.chkboxcontainer {
  display: none;
  @media only screen and (max-width: 56em) {
    display: block;
  }
  position: relative;
}

.hamburg__links {
  position: fixed;
  width: 100%;
  top: 6.8rem;
  right: -100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  & *:link,
  & * :visited {
    text-decoration: none;
    color: $white;
  }
  font-size: 1.2rem;
  gap: 2rem;
  text-align: center;
  background-color: transparent;
  padding: 1rem 3rem;
  transition: right 0.5s ease-in-out;
}

.dark {
  color: $offBlack;
  & *:link,
  & *:visited {
    color: $offBlack !important;
  }
}

.bgdark {
  &::before,
  &::after {
    background-color: $offBlack;
  }
  background-color: $offBlack;
}
