$whiteBlue: #eaf0f7;
$white: #fff;
$black: #000;
$offWhite: rgb(248, 248, 248);
$offGreen: #ddebec;
// $offpurple: #8e83ed;
// $offpurple: #8083ff;
$offpurple: #609df3;
$offmattePurple: #484362;
// $offBlack: #141414;
$offBlack: rgb(4, 9, 24);
$lightpink: #f4ecff;

$gradientBlue1: rgba(1, 10, 68, 1);
$gradientBlue2: rgba(3, 13, 78, 0.7);

$navlink: $offWhite;
$navheading: $offWhite;
$navimage: $offWhite;

$landingText: transparent;
$slogancolor: $offWhite;

$lowertext: $offBlack;
$lowerbar: $offBlack;

$cardBackground: $white;
$cardText: $offBlack;
$cardTitle: $offBlack;

// $cardBackground: $offBlack;
// $cardText: $offWhite;
// $cardTitle: $offWhite;

$servicesbackground: $white;
$servicesheading: $offBlack;
$servicessubheading: $offBlack;
$servicessubsubheading: $offBlack;

// $servicesbackground: $offBlack;
// $servicesheading: $offWhite;
// $servicessubheading: $offWhite;
// $servicessubsubheading: $offWhite;

$whyusbackground: $white;
$whyusbar: $offBlack;
$whyusslogan: $offBlack;
$whyuslower: $offBlack;
$whyusheading: $offBlack;
$whyustext: $offBlack;

$teamtext: $offBlack;
$teambackground: $offWhite;
$teamheading: $offBlack;

$landingrightwidth: 35%;

$imageHeight: 30rem;
$imageWidth: 30rem;
$imageHeightSmall: 25rem;
$imageWidthSmall: 25rem;

$footerbackground: $offBlack;
$footerbuttonborder: $offWhite;
$footerhover: $offWhite;
$footerhovertext: $offBlack;
$footerlink: $offWhite;
$footertext: $offWhite;

$aboutbackground: $white;
$aboutheading: $offBlack;
$abouttext: $offBlack;

// $aboutbackground: $offBlack;
// $aboutheading: $offWhite;
// $abouttext: $offWhite;

$WhatWeDoBackground: $offBlack;
$WhatWeDoTitle: $offWhite;

// $WhatWeDoBackground: $white;
// $WhatWeDoTitle: $offBlack;

$gridItemHeading: $offWhite;
$gridItemText: $offWhite;

// $gridItemHeading: $offBlack;
// $gridItemText: $offBlack;

$contactBackground: #020625;

$ourValuesTitle: $offWhite;
$ourValuesBackground: $offBlack;
$ourValuesValueTitle: $offWhite;
$ourValuesValueDescription: $offWhite;

// $howWeWorkBackground: $offBlack;
// $howWeWorkHeading: $offWhite;
// $howWeWorkSubheading: $offpurple;
// $howWeWorkDescription: $offWhite;
// $howWeWorkIndex: $offWhite;
// $howWeWorkSeperator: $offWhite;

$howWeWorkBackground: $white;
$howWeWorkHeading: $offBlack;
$howWeWorkSubheading: $offpurple;
$howWeWorkDescription: $offBlack;
$howWeWorkIndex: $offBlack;
$howWeWorkSeperator: $offBlack;
