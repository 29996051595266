@import "../variables.scss";

.product {
  display: block;
  width: 32%;
  border: 1px solid $white;
  color: $white;
  background: transparent;
  position: relative;
  transition: all 0.4s ease;
  transform: translateX(-100vw);
  @media only screen and (max-width: 62.5em) {
    width: 85%;
    background-color: $offpurple;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
  &__content {
    min-height: 25rem;
    width: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr 3rem;
    gap: 2rem;
    padding: 1rem;
    transition: all 0.4s ease;

    @media only screen and (min-width: 62.51em) {
      &:hover {
        background-color: $offpurple;
        min-height: 35rem;
      }
      max-height: 25rem;
      overflow: hidden;
    }
    @media only screen and (max-width: 62.5em) {
      min-height: 15rem;
    }
    @media only screen and (max-width: 37.5em) {
      min-height: 20rem;
    }
  }
  &__title {
    font-size: 3rem;
    font-weight: 500;
    line-height: 5rem;
  }

  &__icon {
    height: 3rem;
    width: 3rem;
    transform: rotate(45deg);
  }
  &__description {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.8rem;
    grid-column: span 2;
    align-self: end;
  }
}
