@import "../variables.scss";

.services {
  width: 100%;
  display: flex;
  background-color: $servicesbackground;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0rem;
  scroll-margin-top: 2rem;
  @media only screen and (max-width: 38.65em) {
    scroll-margin-top: 6rem;
  }
  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: 0.8rem;
    color: $servicesheading;
    z-index: 5;
    position: relative;
    opacity: 0;
    top: 5rem;
    transition: all 1s ease;
    .borderBottom {
      width: 0%;
      height: 2px;
      background-color: $offpurple;
      transition: width 1s ease;
      transform: translateX(-0.4rem);
    }
    @media only screen and (min-width: 125em) {
      font-size: 4rem;
    }
  }
  &__subheadingContainer {
    width: 50%;
    text-align: center;
    margin-top: 1rem;
    line-height: 5rem;
    color: $servicessubheading;
    z-index: 5;
    @media only screen and (max-width: 65.25em) {
      width: 75%;
    }
    @media only screen and (max-width: 34.25em) {
      width: 95%;
    }
  }
  &__subheading {
    font-size: 4rem;
    font-weight: 500;
    z-index: 5;
    text-align: center;
    position: relative;
    opacity: 0;
    top: 4rem;
    transition: all 1s ease;
    @media only screen and (max-width: 65.25em) {
      font-size: 3rem;
    }
    @media only screen and (max-width: 26em) {
      font-size: 2.5rem;
    }
    &-subheading {
      color: rgba($servicessubsubheading, 0.8);
      font-size: 1.4rem;
      z-index: 5;
      position: relative;
      top: 4rem;
      opacity: 0;
      transition: all 1s ease;
      @media only screen and (min-width: 125em) {
        font-size: 2rem;
      }
    }
  }
}

.cardsContainer {
  display: flex;
  width: 100%;
  padding: 5rem 5rem;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
  }
}
