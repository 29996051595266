@import "../variables.scss";

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  min-height: 40vh;
  height: 100%;
  transition: all 0.2s ease;
  padding: 1rem;
  position: relative;
  opacity: 0;
  top: 5rem;
  &:hover {
    background-color: $offpurple;
    border-radius: 0.3rem;
  }
  &__heading {
    flex: 1;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 2.4rem;
    font-weight: bold;
    color: $gridItemHeading;
    text-align: center;
  }
  &__text {
    flex: 2;
    font-size: 1.6rem;
    color: $gridItemText;
    text-align: center;
  }
}
