@import "../variables.scss";

.whatWeDo {
  width: 100%;
  background-color: $WhatWeDoBackground;
  padding: 10rem 10rem 5rem 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  @media only screen and (max-width: 62.5em) {
    padding: 10rem 3rem 5rem 3rem;
  }
  @media only screen and (max-width: 48em) {
    flex-direction: column;
    gap: 4rem;
  }
  @media only screen and (max-width: 38.65em) {
    scroll-margin-top: 6rem;
  }
  &__grid {
    height: 100%;
    flex: 1;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    @media only screen and (max-width: 48em) {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 30em) {
      grid-template-rows: repeat(6, 1fr);
      grid-template-columns: 1fr;
    }
  }
  &__title {
    color: $WhatWeDoTitle;
    font-size: 4rem;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-weight: bold;
    letter-spacing: 1.2rem;
    position: relative;
    transition: all 1s ease;
    opacity: 0;
    left: -10rem;
    .borderBottom {
      height: 2px;
      background-color: $offpurple;
      transition: width 1s ease 1s;
      display: none;
      transform: translateY(2rem);
      @media only screen and (max-width: 48em) {
        display: block;
      }
    }
    @media only screen and (max-width: 48em) {
      writing-mode: horizontal-tb;
      letter-spacing: 0.8rem;
      font-weight: 400;
      text-align: center;
      font-size: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
