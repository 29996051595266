@import "../variables.scss";

.expertise {
  width: 100%;
  background-image: linear-gradient(60deg, $offBlack 50%, $white 50%);
  transition: all 1s ease;
  background-size: 250%;
  scroll-margin-top: 2rem;
  @media only screen and (max-width: 85em) {
    background-size: 350%;
  }
  @media only screen and (max-width: 53em) {
    background-size: 400%;
  }
  @media only screen and (max-width: 38.65em) {
    scroll-margin-top: 6rem;
  }
  @media only screen and (max-width: 32.5em) {
    background-size: 575%;
  }
  @media only screen and (max-width: 23em) {
    background-size: 625%;
  }
  background-position: 100%;
  padding: 10rem 15rem 15.2rem;

  @media only screen and (max-width: 90em) {
    padding: 10rem 5rem;
  }

  @media only screen and (max-width: 37.5em) {
    padding: 10rem 2rem;
  }
  &__info {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    color: $white;
    font-weight: 300;
    @media only screen and (max-width: 62.5em) {
      align-items: flex-start;
    }
  }

  &__heading {
    font-size: 5rem;
    line-height: 6.7rem;
    width: 66%;
    position: relative;
    opacity: 0;
    top: 5rem;
    transition: all 1s ease 0.5s;
    letter-spacing: 0.8rem;
    @media only screen and (max-width: 62.5em) {
      width: 100%;
    }
    @media only screen and (max-width: 37.5em) {
      font-size: 2.8rem;
      font-weight: 500;
      line-height: 3.6rem;
    }
  }
  &__text {
    width: 66%;
    font-size: 1.9rem;
    margin-top: 3.5rem;
    line-height: 2.8rem;
    position: relative;
    opacity: 0;
    top: 5rem;
    transition: all 1s ease 0.5s;
    @media only screen and (max-width: 62.5em) {
      width: 100%;
    }
  }

  &__content {
    position: relative;
    min-height: 36rem;
    display: flex;
    margin-top: 3rem;
  }

  &__content-absolute {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    padding: inherit;

    @media only screen and (max-width: 62.5em) {
      flex-direction: column;
      position: relative;
      gap: 3rem;
    }
  }
}
