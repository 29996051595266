@import "../variables.scss";
.ContactUs {
  width: 100%;
  padding: 5rem 20rem 5rem 20rem;
  background-color: $offBlack;
  overflow: hidden;
  @media only screen and (max-width: 56.25em) {
    padding: 5rem 12rem;
  }
  @media only screen and (max-width: 37.5em) {
    padding: 0rem 5rem 2rem 5rem;
  }
  @media screen and (max-width: 30em) {
    padding: 0rem 1rem 2rem 1rem;
  }
}
.contact {
  margin-bottom: 4rem;
  @media only screen and (max-width: 37.5em) {
    margin-bottom: 0rem;
  }
  overflow: hidden;
  background-image: linear-gradient(
      105deg,
      rgba($offWhite, 0.9) 0%,
      rgba($offWhite, 0.9) 50%,
      transparent 50%
    ),
    url("../assets/test1.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  min-height: 60vh;
  clip-path: circle(0vmax at 20% 20%);
  transition: 1.5s all ease;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  @media only screen and (max-width: 93.75em) {
    min-height: 0;
  }
  @media screen and (max-width: 75em) {
    background-image: none;
  }

  &__form {
    width: 50%;
    padding: 6rem;
    @media screen and (max-width: 75em) {
      width: 100%;
      text-align: center;
    }
    @media screen and (max-width: 30em) {
      padding: 5rem 3rem;
    }
  }
}
.form {
  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }
  &__input {
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    color: $offBlack;
    border-radius: 2px;
    background-color: transparent;
    border: none;
    font-family: inherit;
    border-bottom: 1px solid $contactBackground;
    width: 90%;
    transition: all 0.3s;
    display: block;
    @media screen and (max-width: 75em) {
      width: 100%;
      background-color: transparent;
      border-bottom: 2px solid $offWhite;
      color: $offWhite;
      &::placeholder {
        color: $offWhite;
      }
    }
    &:focus {
      outline: none;
    }
  }
  &:focus:invalid {
    border-bottom: 3px solid $contactBackground;
  }
  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }
  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
  }
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-clip: border-box;
  -webkit-background-clip: text;
  color: $contactBackground;
  transition: all 0.2s;
  letter-spacing: 0.2rem;
  @media screen and (max-width: 75em) {
    color: $offWhite;
  }
  @media only screen and (max-width: 30em) {
    font-size: 2.5rem;
  }
}
.contact {
  &__socials > a {
    &,
    &:link,
    &:visited {
      text-transform: uppercase;
      color: $footerlink;
      text-decoration: none;
    }
  }
  &__socials {
    position: relative;
    opacity: 0;
    top: 5rem;
    width: 100%;
    display: flex;
    transition: all 1s ease;
    justify-content: center;
    gap: 2rem;
    font-size: 3rem;
  }
  &__button {
    margin: auto;
    &,
    &:link,
    &:visited {
      text-transform: uppercase;
      color: $contactBackground;
      text-decoration: none;
      padding: 1rem 4rem;
      display: inline-block;
      border-radius: 100px;
      border: 1px solid $contactBackground;
      font-size: 1.5rem;
      transform: translateY(0);
      transition: all 0.3s ease;
      @media screen and(max-width:75em) {
        color: $offWhite;
        border: 1px solid $offWhite;
        border-radius: 0.2rem;
      }
      &:hover {
        background-color: $offBlack;
        @media only screen and (max-width: 75em) {
          background-color: $offpurple;
        }
        color: $offWhite;
        transform: translateY(-0.5rem);
        box-shadow: 0 8px 0px 0px rgba($offBlack, 0.2);
        @media only screen and (max-width: 75em) {
          box-shadow: 0 8px 0px 0px rgba($offpurple, 0.2);
        }
      }
    }
  }
}
