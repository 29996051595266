@import "../variables.scss";

.footer {
  width: 100%;
  padding: 7rem 0 0 0;
  background-color: $footerbackground;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  color: $footertext;
  justify-content: center;
  scroll-margin-top: 7rem;
  @media only screen and (max-width: 38.65em) {
    scroll-margin-top: 13rem;
  }
  @media only screen and (max-width: 62.5em) {
    padding: 5rem 0 0 0;
  }
  @media only screen and (max-width: 38.75em) {
    padding: 3.5rem 0 0 0;
    gap: 2rem;
  }
  @media only screen and (max-width: 26.25em) {
    padding: 3rem 0 0 0;
    gap: 2rem;
  }
  &__heading {
    font-size: 4.5rem;
    width: 60%;
    text-align: center;
    position: relative;
    opacity: 0;
    top: -10rem;
    transition: all 1s ease;
    @media only screen and (min-width: 125em) {
      font-size: 5rem;
      width: 50%;
    }
    @media only screen and (max-width: 81.25em) {
      font-size: 3.75rem;
      width: 65%;
    }
    @media only screen and (max-width: 62.5em) {
      font-size: 3rem;
    }
    @media only screen and (max-width: 50em) {
      font-size: 2.5rem;
      width: 70%;
    }
    @media only screen and (max-width: 38.75em) {
      font-size: 2rem;
      width: 85%;
    }
    @media only screen and (max-width: 26.25em) {
      font-size: 1.8rem;
      width: 90%;
    }
  }
  &__text {
    font-size: 1.5rem;
    text-align: center;
    position: relative;
    opacity: 0;
    top: -10rem;
    transition: all 1s ease;
    @media only screen and (max-width: 50em) {
      font-size: 1.25rem;
    }
    @media only screen and (max-width: 38.75em) {
      font-size: 1.2rem;
      width: 85%;
    }
    @media only screen and (max-width: 26.25em) {
      font-size: 1rem;
    }
  }
}
