@import "../variables.scss";

.article {
  width: 25%;
  padding: 0 3.2rem 17.2rem;
  position: relative;
  opacity: 0;
  transition: all 1s ease;
  top: 10rem;
  @media only screen and (max-width: 109.375em) {
    padding: 0 2.4rem 19.2rem;
  }

  @media only screen and (max-width: 81.25em) {
    padding: 0 2.1rem 14.1rem;
  }

  @media only screen and (max-width: 59.375em) {
    padding: 0 1.5rem 9.7rem;
  }

  @media only screen and (max-width: 50.9375em) {
    width: 100%;
    border-top: 1px solid $white;
    padding: 0.9rem 0 8.9rem 9.2rem;

    &:last-child {
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  &:first-child {
    padding-left: 0;
    @media only screen and (max-width: 50.9375em) {
      padding-left: 9.2rem;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 0.1rem;
    height: calc(100% - 1rem);
    background-color: $howWeWorkSeperator;
    position: absolute;
    bottom: 0;
    right: 0;
    @media only screen and (max-width: 50.9375em) {
      display: none;
    }
  }

  &__heading {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.6rem;
    color: $howWeWorkSubheading;
    margin: 0;

    @media only screen and (max-width: 109.375em) {
      font-size: 1.9rem;
      line-height: 2.8rem;
    }

    @media only screen and (max-width: 68.75em) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    @media only screen and (max-width: 50.9375em) {
      font-size: 1.9rem;
      line-height: 2.8rem;
      margin: 0;
    }
  }

  &__description {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 3.6rem;
    margin: 2.4rem 0;
    color: $howWeWorkDescription;

    @media screen and (max-width: 109.375em) {
      font-size: 1.9rem;
      line-height: 2.8rem;
      margin: 1.8rem 0;
    }

    @media only screen and (max-width: 68.75em) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    @media only screen and (max-width: 50.9375em) {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 1.6rem 0;
    }
  }

  &__index {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 300;
    font-size: 10rem;
    line-height: 0.8;
    color: $howWeWorkIndex;
    position: absolute;
    bottom: 0;

    @media only screen and (max-width: 50.9375em) {
      font-size: 6.4rem;
      left: -0.2px;
      top: 1.3rem;
    }
  }

  &:first-child &__index {
    left: 0;
  }
}
